import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import TabLayout from '../layout/dataModelTabLayout';
import ModelService from '../../service/ModelService';
import './result.css';
import Prediction from '../graph/prediction/prediction';
import Histogram from '../graph/histogram/histogram';
import ScatterPlot from '../graph/plot/scatterPlot';
import BoxPlot from '../graph/plot/boxPlot';
import GraphTabLayout from '../layout/graphTabLayout';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Papa from 'papaparse';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import S3Service from '../../service/S3service';
import Report from '../report/report';
import { useNavigate } from 'react-router-dom';

const Result = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(2);
    const [graphActiveIndex, setGraphActiveIndex] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [showGraphs, setShowGraphs] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const toast = useRef(null);
    const resultContainerRef = useRef(null);
    const graphLayoutRef = useRef(null);
    const layoutRef = useRef(null);
    const summaryRef = useRef(null);
    const [csvData, setCsvData] = useState([]); // For Prediction component
    const [healthData, setHealthData] = useState([]); // For Histogram component
    const [utilizationData, setUtilizationData] = useState([]); // For ScatterPlot component
    const [screenshotData, setScreenshotData] = useState(null);
    const [deployedRows, setDeployedRows] = useState([]);


    useEffect(() => {
        fetchData();
        fetchSummaryData();
        const interval = setInterval(() => {
            fetchData();
        }, 120000); // 120000 ms = 2 minutes

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const fetchData = async () => {
        try {
            const response = await ModelService.getAllJsonData(0,100000);
            const sortedData = response?.data
                .map(item => ({
                    ...item,
                    created_at: new Date(item.created_at), // Convert Timestamp to Date object
                    product: Array.isArray(item.product) ? item.product.join(', ') : item.product
                }))
                .sort((a, b) => b.created_at - a.created_at) // Sort by date (most recent first)
                .map(item => ({
                    ...item,
                    created_at: formatDate(item.created_at)
                }));
            setTableData(sortedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const formatDate = (date) => {
        return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Use 24-hour time
        });
    };
    


    const fetchSummaryData = () => {
        fetch('/csv-data/summary/summary.csv')
            .then(response => response.text())
            .then(csvText => {
                Papa.parse(csvText, {
                    header: false, // Assuming CSV has no header
                    dynamicTyping: true,
                    complete: (results) => {
                        // Transforming the data to an array of objects with 'label' and 'value'
                        const data = results.data.map(row => ({
                            label: row[0],
                            value: row[1],
                        }));
                        setSummaryData(data);
                    },
                });
            })
            .catch(error => console.error('Error fetching CSV data:', error));
    };

    const totalRecords = tableData.length;

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const onResponseClick = async (rowData) => {
        try {
            setSelectedResponse(rowData);
            setShowGraphs(true); // Show graphs when a response is clicked
            setShowButton(true);

            // Extract s3folder_guid from rowData
            const { s3folder_guid } = rowData;

            // Fetch the list of files inside the folder
            const fileList = await S3Service.getFileList(s3folder_guid);

            // Initialize objects to store parsed data for each file
            let predictionData = [];
            let healthData = [];
            let utilizationData = [];

            // Function to download and parse a file
            const downloadAndParseFile = async (filePath) => {
                try {
                    const csvBlob = await S3Service.downloadFile(filePath);
                    const csvText = await csvBlob.text();
                    return new Promise((resolve, reject) => {
                        Papa.parse(csvText, {
                            header: true,
                            dynamicTyping: true,
                            complete: (results) => {
                                resolve(results.data);
                            },
                            error: (error) => {
                                reject(error);
                            },
                        });
                    });
                } catch (error) {
                    console.error(`Error downloading or parsing file ${filePath}:`, error);
                    return null; // Return null if there is an error
                }
            };

            // Download and parse each file
            for (const file of fileList) {
                const filePath = `${s3folder_guid + file}`;
                let parsedData = await downloadAndParseFile(filePath);

                if (!parsedData || parsedData.length === 0) {
                    console.warn(`No data found in file ${filePath}`);
                    continue; // Skip if no data is found
                }

                if (file === "Time_True_Pred.csv") {
                    predictionData = parsedData;
                } else if (file === "health.csv") {
                    healthData = parsedData;
                } else if (file === "utilization.csv") {
                    utilizationData = parsedData;
                }
            }

            // Set the parsed data to respective components
            setCsvData(predictionData); // For Prediction component
            setHealthData(healthData); // For Histogram component
            setUtilizationData(utilizationData); // For ScatterPlot component
            // Set data loaded state to true
            setIsDataLoaded(true);
        } catch (error) {
            console.error('Error downloading or parsing CSV files:', error);
        }
    };


    const paginatedData = tableData.slice(first, first + rows);

    const renderActiveTab = () => {
        if (!showGraphs || !selectedResponse) {
            return <p className="no-data-message">Click on a Response to view graphs</p>;
        }

        switch (graphActiveIndex) {
            case 0:
                return <Prediction selectedResponse={selectedResponse} summaryData={summaryData} csvData={csvData} ref={layoutRef} />;
            case 1:
                return <Histogram selectedResponse={selectedResponse} summaryData={summaryData} csvData={healthData} summaryRef={summaryRef} />;
            case 2:
                return <ScatterPlot selectedResponse={selectedResponse} summaryData={summaryData} csvData={utilizationData} />;
            case 3:
                return <BoxPlot selectedResponse={selectedResponse} summaryData={summaryData} />;
            default:
                return null;
        }
    };

    const showPublishToast = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: message });
    };

    const handleSelectResponse = () => {
        captureScreenshot();
        showPublishToast('Report has been published');
        setIsPublished(true);
    };

    const captureScreenshot = () => {
        const input = layoutRef.current;
        console.log("layoutRef.current:", input);

        if (input) {
            const screenshotElement = input.captureScreenshot();
            console.log("screenshotElement:", screenshotElement);
            console.log("Capturing screenshot...");
            if (screenshotElement) {
                requestAnimationFrame(() => {
                    html2canvas(screenshotElement, { scale: 2, willReadFrequently: true }).then((canvas) => {
                        console.log("Canvas generated");
                        const imgData = canvas.toDataURL('image/jpeg');
                        setScreenshotData(imgData);
                        // const pdf = new jsPDF({
                        //     orientation: 'portrait', // or 'landscape'
                        //     unit: 'mm',
                        //     format: [canvas.width * 0.33, canvas.height * 1.25] // Adjust dimensions to fit content
                        // });
                        // pdf.addImage(imgData, 'JPEG', 0, 0);
                        // pdf.save('report.pdf'); // Trigger download
                        // console.log("PDF saved");
                    }).catch((error) => {
                        console.error("Error generating canvas:", error);
                    });
                });
            }
        } else {
            console.error("No input found for capturing screenshot");
        }
    };

    const handleDownloadReport = () => {
        // Navigate to the /portal/report route with screenshotData
        navigate('/portal/report', { state: { predictionData: csvData, healthData, utilizationData, screenshotData } });
    };


    const statusBodyTemplate = (rowData) => {
        let statusClassName;
        switch (rowData.status) {
            case 'Active':
                statusClassName = 'status-active';
                break;
            case 'Training Finished':
                statusClassName = 'status-complete';
                break;
            case 'Training Failed':
                statusClassName = 'status-failed';
                break;
            default:
                statusClassName = '';
        }

        return (
            <span className={statusClassName}>
                {rowData.status}
            </span>
        );
    };

    const renderConditionalColumn = (rowData, field) => {
        return rowData.status === 'Training Finished' ? rowData[field] : '-';
    };

    const parseDate = (dateString) => {
        const [day, month, year, time] = dateString.split(/[/, ]+/);
        return new Date(`${year}-${month}-${day}T${time}`);
    };

    const formatDateForBackend = (date) => {
        // Format the date as yyyy-MM-dd'T'HH:mm:ss
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };
    
    
    
    const handleDeploy = async (rowData) => {
        try {
            const modelToDeploy = rowData.model;
            const rowsToDeploy = tableData.filter(row => row.model === modelToDeploy);
    
            const updatedRows = rowsToDeploy.map(row => {
                const rawCreatedAt = row.created_at;
                const dateObj = parseDate(rawCreatedAt);
                
                if (isNaN(dateObj.getTime())) {
                    throw new Error(`Invalid date format: ${rawCreatedAt}`);
                }
    
                const formattedDate = formatDateForBackend(dateObj);
    
                return {
                    ...row,
                    product: row.product ? row.product.split(', ').map(item => item.trim()) : [],
                    created_at: formattedDate,
                    deployment_status: 'Success'
                };
            });
    
            for (const row of updatedRows) {
                await ModelService.updateJsonData(row.id, row);
            }
    
            setTableData(prevTableData =>
                prevTableData.map(row =>
                    row.model === modelToDeploy
                        ? { ...row, deployment_status: 'Success' }
                        : row
                )
            );
    
            setDeployedRows(prevDeployedRows => [...prevDeployedRows, ...updatedRows.map(row => row.id)]);
    
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Model deployed successfully.' });
        } catch (error) {
            console.error('Error deploying model:', error.response?.data || error.message);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to deploy the model.' });
        }
    };
    


    const renderDeployButton = (rowData) => {
        const isDeployed = rowData.deployment_status === 'Success';
        const isEnabled = rowData.status === 'Training Finished';
        return (
            <Button
                icon="pi pi-cloud-upload"
                className={`p-button-rounded ${isDeployed ? 'p-button-success' : 'p-button-warning'}`}
                onClick={() => handleDeploy(rowData)}
                disabled={!isEnabled}
            />
        );
    };



    return (
        <TabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
            <Toast ref={toast} position="bottom-right" />
            <div ref={resultContainerRef} className='result-container'>
                <div className="upper-half">
                    <div className="datatable-styles">
                        <DataTable
                            value={paginatedData}
                            resizableColumns
                            showGridlines
                            className="p-datatable-striped p-datatable-smaller"
                            selectionMode="single"
                            sortField="created_at"
                            sortOrder={-1}
                            onSort={(e) => ('e', e)}
                        >
                            <Column field="file" header="Data File" />
                            <Column field="options" header="Options" />
                            <Column field="product" header="Product" />
                            <Column field="response_ops" header="Response ops" />
                            <Column field="model" header="Model" />
                            <Column
                                field="status"
                                header="Status"
                                body={statusBodyTemplate}
                            />
                            <Column
                                field="response"
                                header="Response"
                                body={(rowData) => (
                                    <span
                                        className="response-clickable"
                                        onClick={() => onResponseClick(rowData)}
                                    >
                                        {rowData.response}
                                    </span>
                                )}
                            />
                            <Column field="mae" header="MAE"
                                body={(rowData) => renderConditionalColumn(rowData, 'mae')}
                            />
                            <Column field="rmse" header="RMSE"
                                body={(rowData) => renderConditionalColumn(rowData, 'rmse')}
                            />
                            <Column field="r_square" header="R square"
                                body={(rowData) => renderConditionalColumn(rowData, 'r_square')}
                            />
                            {/* <Column field="mae_control_range" header="MAE control range" /> */}
                            <Column field="created_at" header="Created Date" />
                            <Column
                                body={(rowData) => renderDeployButton(rowData)}
                                header="Deploy"
                            />
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={totalRecords}
                            rowsPerPageOptions={[5, 10, 20]}
                            onPageChange={onPageChange}
                            className="paginator"
                        />
                    </div>
                </div>

                <div className="lower-half">
                    <GraphTabLayout
                        graphActiveIndex={graphActiveIndex}
                        setGraphActiveIndex={setGraphActiveIndex}
                    // ref={graphLayoutRef} 
                    >
                        {renderActiveTab()}
                        <div className="result-button">
                            <Button
                                disabled={!isDataLoaded}
                                type="button"
                                label="Publish"
                                className="p-button-primary button-class"
                                onClick={handleSelectResponse}
                            />
                            <Button disabled={!isPublished}
                                label="Generate Report"
                                onClick={handleDownloadReport} />
                        </div>
                    </GraphTabLayout>
                </div>
            </div>
        </TabLayout>
    );
};

export default Result;
