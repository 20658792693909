import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { FaPaperPlane, FaPaperclip } from 'react-icons/fa';
import ChatService from '../../service/ChatService';
import { v4 as uuidv4 } from 'uuid';
import './chat.css';

const Chat = () => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [context, setContext] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const chatEndRef = useRef(null);

    // Auto-scroll to the bottom when new messages are added
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === "text/plain" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            setSelectedFile(file);
            console.log(`File accepted: ${file.name}`);
        } else {
            alert("Only .txt, .doc, and .docx files are allowed.");
            setSelectedFile(null);
        }
    };

    const handleSend = async () => {
        if (message.trim() || selectedFile) {
            if (message.trim()) {
                await sendQuestionToAPI(message); 
                setMessage(''); 
            }

            if (selectedFile) {
                await handleFileUpload(selectedFile); 
                setSelectedFile(null); 
            }
        }
    };

    const handleFileUpload = async (file) => {
        try {
            // Parse the file to extract questions
            const questionsFromFile = await parseFile(file);
    
            if (questionsFromFile.length > 0) {
                // Update context with file-uploaded questions
                setContext(prevContext => [...prevContext, ...questionsFromFile]);
    
                // Send each question from the file to the API
                for (let question of questionsFromFile) {
                    await sendQuestionToAPI(question);
                }
            } else {
                setChatHistory(prevHistory => [...prevHistory, { text: "No valid questions found in the file.", fromUser: false }]);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            setChatHistory(prevHistory => [...prevHistory, { text: "Error uploading file.", fromUser: false }]);
        }
    };
    

    const parseFile = async (file) => {
        const text = await readFileContent(file);
        console.log("File content:", text);
    
        // Extract questions from the file content
        const questions = extractQuestions(text);
        return questions;
    };
    

    const extractQuestions = (text) => {
        const lines = text.split('\n');
        return lines.filter(line => line.trim().endsWith('?')); 
    };    

    const sendQuestionToAPI = async (question) => {
        const userMessage = { text: question, fromUser: true };
        setChatHistory(prevHistory => [...prevHistory, userMessage]);
        
        setContext(prevContext => [...prevContext, question]);
    
        setIsTyping(true);
        
        const payload = {
            id: uuidv4(),
            user_query: question,
            context: context, 
            modelname: "GPT"
        };
    
        try {
            const response = await ChatService.getAnswer(payload);
            const botMessage = { text: response.response, fromUser: false };
            setChatHistory(prevHistory => [...prevHistory, botMessage]);
        } catch (error) {
            setChatHistory(prevHistory => [...prevHistory, { text: "Error retrieving response from API.", fromUser: false }]);
            console.error("Error retrieving response from API:", error);
        } finally {
            setIsTyping(false);
        }
    };    
    
    useEffect(() => {
        const welcomeMessage = "Welcome! How can I assist you today?";
        setChatHistory([{ text: welcomeMessage, fromUser: false }]);
    }, []);

    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = (e) => reject(e);
            reader.readAsText(file);
        });
    };

    return (
        <div className="chat-dialog">
            <div className="chat-header">
                <div><h2>Data Talk</h2><h4>Download Chat</h4></div>                            
            </div>           
            <div className="chat-body">
                <div className="chat-history">
                    {chatHistory.map((entry, index) => (
                        <div key={index} className={`chat-message ${entry.fromUser ? 'user-message' : 'api-message'}`}>
                            {entry.fromUser ? (
                                <div>{entry.text}</div>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: entry.text }} />
                            )}
                        </div>
                    ))}
                    {isTyping && (
                        <div className="chat-message typing-indicator">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    )}
                    <div ref={chatEndRef} />
                </div>
                <div className="chat-input">
                    <div className="chat-input-wrapper">
                        <InputText
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your question..."
                            className="chat-input-field"
                        />
                        <label htmlFor="file-upload" className="file-upload-label">
                            <FaPaperclip className="chat-attachment-icon" />
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            accept=".txt,.doc,.docx"
                            onChange={handleFileChange}
                            className="file-upload-input"
                        />
                        <FaPaperPlane
                            className="chat-send-icon"
                            onClick={handleSend}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
