import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PrimeIcons } from 'primereact/api';
import { useUser } from '../../context/userContext';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import './menubar.css';

const Menubar = () => {
    const location = useLocation();
    const { user } = useUser();

    const renderMenuItemWithRoleCheck = (path, icon, label, requiredRoles) => {
        const userRoles = user?.data?.roles?.map(role => role.name.toUpperCase()) || [];
        const normalizedRequiredRoles = requiredRoles.map(role => role.toUpperCase());

        // Check if any required role is included in the user's roles
        if (userRoles.length > 0 && normalizedRequiredRoles.some(role => userRoles.includes(role))) {
            return (
                <li className={location.pathname === path ? 'active' : ''} key={path}>
                    <Link to={path}><i className={icon}></i> {label}</Link>
                </li>
            );
        }
        return null;
    };

    return (

        
        <div className="menubar">
            
            <div className="menulogo">
                <img src="/asset/data/logow.png" alt="TIA Studio Logo" className="logo" />
                <div className="settingicon">
                {/* <Button
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-text"
                    tooltip="Settings"
                    tooltipOptions={{ position: 'bottom' }}
                /> */}
                </div>
                <ul>
                {renderMenuItemWithRoleCheck('/portal/home', PrimeIcons.HOME, 'Dashboard', ['BUSINESS_USER', 'USER', 'SUPER_ADMIN'])}
                {renderMenuItemWithRoleCheck('/portal/data', PrimeIcons.DATABASE, 'Data', ['USER', 'SUPER_ADMIN'])}
                {renderMenuItemWithRoleCheck('/portal/model', PrimeIcons.SITEMAP, 'Train Model', ['SUPER_ADMIN', 'USER'])}
                {renderMenuItemWithRoleCheck('/portal/run', PrimeIcons.SYNC, 'Run Model', ['SUPER_ADMIN', 'USER'])}
                {renderMenuItemWithRoleCheck('/portal/users', PrimeIcons.USERS, 'Users', ['SUPER_ADMIN', 'ADMIN'])}
                {/* {renderMenuItemWithRoleCheck('/portal/report', PrimeIcons.FILE, 'Report', ['BUSINESS_USER', 'USER', 'SUPER_ADMIN'])} */}
                {renderMenuItemWithRoleCheck('/portal/chat', PrimeIcons.COMMENTS, 'Data Talk', ['SUPER_ADMIN', 'USER'])}
            </ul>
            </div>
            <ul className="bottom-menu">
                <div className="right-section">
                    {user && (
                        <div className="user-info">
                            <Avatar
                                icon="pi pi-user"
                                shape="circle"
                                size="small"
                                className="p-mr-2"
                            />

                            <div className="user-details">
                                <p>{user.data.name}</p>
                                {/* <p>{user.data.email}</p> */}
                            </div>
                            <div className="menufoot">
                            <Link to="/portal/logout"><i className={PrimeIcons.SIGN_OUT}></i></Link>
                            </div>
                        </div>
                    )}
                </div>
             
           
            
                <div className="menufoot">
            
                TIA Studio v1.0.0
            </div>
            </ul>

        </div>
    );
};
export default Menubar;
